// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galston-concert-programs-js": () => import("./../../../src/pages/galston/concert-programs.js" /* webpackChunkName: "component---src-pages-galston-concert-programs-js" */),
  "component---src-pages-galston-gottfried-galston-js": () => import("./../../../src/pages/galston/gottfried-galston.js" /* webpackChunkName: "component---src-pages-galston-gottfried-galston-js" */),
  "component---src-pages-galston-index-js": () => import("./../../../src/pages/galston/index.js" /* webpackChunkName: "component---src-pages-galston-index-js" */),
  "component---src-pages-galston-music-pedagogy-js": () => import("./../../../src/pages/galston/music-pedagogy.js" /* webpackChunkName: "component---src-pages-galston-music-pedagogy-js" */),
  "component---src-pages-galston-resources-js": () => import("./../../../src/pages/galston/resources.js" /* webpackChunkName: "component---src-pages-galston-resources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

